import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import { validationMixin } from "vuelidate";
import {
  required,
  email,
  minValue,
  maxLength,
  numeric
} from "vuelidate/lib/validators";
import { sanitize } from "dompurify";

import sendEmailContactForm, {
  ContactFormApiClientPayload
} from "@/api/contact/contact-api";
import {
  createMathChallenge,
  MathChallenge
} from "@/plugins/challenge/math-challenge";

interface ContactFormShape extends ContactFormApiClientPayload {
  challengeAnswer: number | undefined;
}

const FORM_STATE_NOT_SUBMITTED: unique symbol = Symbol(
  "Form.State.NotSubmitted"
);
const FORM_STATE_SUBMITTED: unique symbol = Symbol("Form.State.Submitted");
const FORM_STATE_REJECTED: unique symbol = Symbol("Form.State.Rejected");

type FormState =
  | typeof FORM_STATE_NOT_SUBMITTED
  | typeof FORM_STATE_SUBMITTED
  | typeof FORM_STATE_REJECTED;

interface FormMeta {
  state: FormState;
  message: string;
}

const FORM_PRISTINE_META: FormMeta = {
  state: FORM_STATE_SUBMITTED,
  message: ""
};

const CONTACT_FORM_PRISTINE_STATE: ContactFormShape = {
  name: "",
  email: "",
  message: "",
  challengeAnswer: undefined
};

function isEqualToCorrectAnswer(
  correctAnswer: number,
  userAnswer: ContactFormShape["challengeAnswer"]
): boolean {
  return userAnswer !== undefined && +userAnswer === correctAnswer;
}

@Component<EmailFormMixin>({
  mixins: [validationMixin],
  validations() {
    return {
      form: {
        name: { required },
        email: { required, email },
        message: { required, maxLength: maxLength(255) },
        challengeAnswer: {
          required,
          numeric,
          minValue: minValue(0),
          isEqualToCorrectAnswer: isEqualToCorrectAnswer.bind(
            null,
            this.challenge.result
          )
        }
      }
    };
  }
})
export class EmailFormMixin extends Vue {
  formMeta: FormMeta = FORM_PRISTINE_META;
  formStateEnum = {
    FORM_STATE_NOT_SUBMITTED,
    FORM_STATE_SUBMITTED,
    FORM_STATE_REJECTED
  };
  isPending = false;
  challenge: MathChallenge = createMathChallenge();
  form: ContactFormShape = {
    ...CONTACT_FORM_PRISTINE_STATE
  };

  get isFormSubmissionDisabled(): boolean {
    return this.$v.$invalid || this.isPending;
  }

  setChallenge(): void {
    this.challenge = createMathChallenge();
  }

  reset(newValues?: Partial<ContactFormShape>): void {
    this.$v?.$reset();
    this.form = {
      ...this.form,
      ...newValues
    };
  }

  async sendForm(): Promise<void> {
    try {
      this.isPending = true;
      this.formMeta = FORM_PRISTINE_META;

      await sendEmailContactForm({
        name: sanitize(this.form.name),
        email: sanitize(this.form.email),
        message: sanitize(this.form.message)
      });

      this.formMeta = {
        state: FORM_STATE_SUBMITTED,
        message: "Thank you for your message!"
      };
    } catch {
      this.formMeta = {
        state: FORM_STATE_REJECTED,
        message: "There was an error. Please try again"
      };
    } finally {
      this.isPending = false;
      this.reset({
        challengeAnswer: CONTACT_FORM_PRISTINE_STATE.challengeAnswer
      });
      this.setChallenge();
    }
  }

  beforeDestroy(): void {
    this.formMeta = FORM_PRISTINE_META;
    this.reset(CONTACT_FORM_PRISTINE_STATE);
    this.setChallenge();
  }
}
