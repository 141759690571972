import axios, { AxiosInstance } from "axios";

const getApiClient = (endpointBaseUrl: string): AxiosInstance => {
  return axios.create({
    baseURL: endpointBaseUrl
  });
};

export default getApiClient(process.env.VUE_APP_API_BASE_URL);
export { getApiClient };
