import { AxiosInstance, AxiosResponse } from "axios";
import apiClient from "@/api";

interface ContactFormApiClientPayload {
  name: string;
  email: string;
  message: string;
}

type EmailContactFormSendFn = (
  apiClient: AxiosInstance,
  payload: ContactFormApiClientPayload
) => Promise<AxiosResponse<string>>;

const sendApiClientEmailContactForm: EmailContactFormSendFn = (
  apiClient: AxiosInstance,
  payload: ContactFormApiClientPayload
) => {
  return apiClient.post("email/send", payload);
};

const sendEmailContactForm = (payload: ContactFormApiClientPayload) =>
  sendApiClientEmailContactForm(apiClient, payload);

export default sendEmailContactForm;
export { ContactFormApiClientPayload, sendApiClientEmailContactForm };
