interface MathChallengeOptions {
  minNumber?: number;
  maxNumber?: number;
}

export interface MathChallenge {
  x: number;
  y: number;
  result: number;
}

const DEFAULT_MIN_NUMBER = 16;
const DEFAULT_MAX_NUMBER = 32;

function getRandomNumber(min: number, max: number): number {
  return Math.round(Math.random() * (max - min) + min);
}

export function createMathChallenge(
  options?: MathChallengeOptions
): MathChallenge {
  const { minNumber = DEFAULT_MIN_NUMBER, maxNumber = DEFAULT_MAX_NUMBER } =
    options ?? {};

  const [x, y] = Array.from({ length: 2 }, () =>
    getRandomNumber(minNumber, maxNumber)
  );

  return {
    x,
    y,
    result: x + y
  };
}
